import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { Whitelist } from "./whitelist";
import { TextSubTitle } from "./styles/globalStyles";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  border-radius: 5px;
  border: none;
  background-color: var(--secondary);
    font-family: "Bebas Neue", Raleway, sans-serif;
  font-size: 30px;
  padding: 19px;
  font-weight: bold;
  color: var(--accent);
  width: 100%;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  max-width: 500px;
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const Ulist = styled.ul``;

export const List = styled.li``;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click 'Mint' to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    COST_TYPE: "",
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  console.log(Whitelist.count());
  console.log(Whitelist.getRoot());

  const claimingNftPublic = () => {
    console.log("Claiming off of public sale");
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);

    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .PublicMint(mintAmount, blockchain.account)
      .send({
        gasPrice: 150000000000,
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  }

  const claimNftWhitelist = () => {
    console.log("Claiming off of whitelist");
    let tCost = CONFIG.DISPLAY_COST * mintAmount;
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);

    let proof = Whitelist.getProof(blockchain.account);

    let verified = Whitelist.isVerified(blockchain.account);

    if (!verified && !Whitelist.isOpen) {
      setFeedback(`Account ${blockchain.account} is not on the whitelist`);
      return;
    }

    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .WhitelistMint(mintAmount, proof)
      .send({
        gasPrice: 350000000000,
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/publicconfig.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        style={{
          padding: 24,
          backgroundColor: "var(--accent)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #303030",
        }}
      >
        <StyledImg
          style={{ maxWidth: 150 }}
          alt={"example"}
          src={"/config/images/logo.png"}
        />
        {/* <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 25,
                color: "var(--primary)",
                textTransform: "uppercase",
                flex: 1,
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {CONFIG.NFT_NAME}
        </s.TextTitle> */}
        <s.Container
          style={{
            textAlign: "center",
            color: "var(--primary)",
            textTransform: "uppercase",
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
        >
          <StyledLink
            className="social-link"
            target={"_blank"}
            href="https://twitter.com/EldersGrace"
          >
            <i class="fab fa-twitter"></i>
          </StyledLink>
          <StyledLink
            className="social-link"
            target={"_blank"}
            href="https://discord.com/invite/Ecq2y6CjCw"
          >
            <i class="fab fa-discord"></i>
          </StyledLink>
        </s.Container>
      </s.Container>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--accent)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <ResponsiveWrapper
          flex={1}
          style={{ padding: 24, maxWidth: 1200 }}
          test
        >
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/nft.png"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              padding: 24,
            }}
          >

            {Whitelist.isOpen ? (
              <>



                {blockchain.account === "" || blockchain.smartContract === null ? (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        fontSize: "40px",
                        fontWeight: "bold",
                        color: "var(--primary)",
                        textTransform: "uppercase",
                      }}
                    >
                      Mint Your {CONFIG.NFT_NAME}
                    </s.TextTitle>
                    <s.TextSubTitle
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST} {CONFIG.COST_TYPE}
                    </s.TextSubTitle>
                  </>
                ) : (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        fontSize: 40,
                        fontWeight: "bold",
                        color: "var(--primary)",
                        textTransform: "uppercase",
                      }}
                    >
                      Current Supply<br />
                      {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                    </s.TextTitle>
                    <s.TextSubTitle
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST} {CONFIG.COST_TYPE}
                    </s.TextSubTitle>
                  </>
                )}
                {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                  <>
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      The sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      You can still find {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                      {CONFIG.MARKETPLACE}
                    </StyledLink>
                  </>
                ) : (
                  <>

                    <s.SpacerSmall />
                    {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                      <s.Container ai={"center"} jc={"center"}>
                        <StyledButton
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}
                        >
                          CONNECT METAMASK
                        </StyledButton>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          Connect to the {CONFIG.NETWORK.NAME} network
                        </s.TextDescription>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>
                    ) : (
                      <>
                        <s.TextSubTitle
                          style={{
                            textAlign: "center",
                            color: "var(--primary)",
                            textTransform: "uppercase",
                          }}
                        >
                          {feedback}
                        </s.TextSubTitle>
                        <s.SpacerMedium />

                        <s.SpacerSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          {Whitelist.isVerified(blockchain.account) || Whitelist.isPublic ? (
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();

                                if (!Whitelist.isPublic) {
                                  claimNftWhitelist();
                                }
                                else {
                                  claimingNftPublic();
                                }

                                getData();
                              }}
                            >
                              {claimingNft ? "BUSY" : "MINT"}
                            </StyledButton>
                          ) : (
                            <s.TextSubTitle
                              style={{
                                textAlign: "center",
                                color: "var(--primary-text)",
                                textTransform: "uppercase",
                              }}
                            >
                              Sorry your address currently isn't whitelisted.<br />
                              Please wait until the public sale on April 14th at 2:00PM UTC to mint your {CONFIG.SYMBOL}.
                            </s.TextSubTitle>
                          )
                          }
                          <s.SpacerSmall />
                        </s.Container>
                      </>
                    )}
                  </>
                )}
                <s.SpacerMedium />


              </>
            ) : (
              <>
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "var(--primary)",
                    textTransform: "uppercase",
                  }}
                >
                  The sale of {CONFIG.NFT_NAME} is complete!<br /><br />
                  Please check out our collection on <a href="https://opensea.io/collection/elders-grace-founders-collection"
                    style={{ color: "var(--primary)" }}>
                    OpenSea
                  </a>
                </s.TextTitle>
              </>
            )
            }

          </s.Container>
        </ResponsiveWrapper>
      </s.Container >
      <s.Container
        jc={"center"}
        ai={"center"}
        style={{
          padding: 24,
          backgroundColor: "var(--accent)",
          borderTop: "1px solid #303030",
        }}
      >
        <s.TextDescription
          style={{
            textAlign: "center",
            color: "var(--accent-text)",
          }}
        >
          <StyledLink
            target={"_blank"}
            href="https://opensea.io/collection/elders-grace-founders-collection"
            style={{
              color: "var(--primary-text)",
              fontSize: "16px",
              lineHeight: "1.6",
            }}
          >View on OpenSea
          </StyledLink> |
          <StyledLink
            target={"_blank"}
            href="https://raritysniper.com/nft-drops-calendar"
            style={{
              color: "var(--primary-text)",
              fontSize: "16px",
              lineHeight: "1.6",
            }}
          >NFT Drops
          </StyledLink> | {CONFIG.NFT_NAME} | All Rights Reserved.

        </s.TextDescription>

      </s.Container>
    </s.Screen >
  );
}

export default App;
